import React from 'react';

const ActiveStudents = () => {
  return (
    <div className="bg-white p-4 flex justify-between items-center w-full">
      <div className="text-left">
        <p className="text-3xl font-bold m-0">151</p>
        <p className="m-0">Active Students</p>
        <p className="m-0">Total Students: 152</p>
      </div>
      <div className="bg-gradient-to-r from-cyan-400 to-blue-500 text-white rounded-full flex justify-center items-center w-12 h-12">
        <i className="fa-solid fa-users text-2xl"></i>
      </div>
    </div>
  );
};

export default ActiveStudents;
