import React, { useState } from "react";
import FeeReceivingHistoryModal from "../components/FeeReceivingHistoryModal";

const FeeReceiving = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div
      className="flex flex-col gap-2"
      style={{ fontFamily: "Roboto, sans-serif" }}
    >
      <div className="bg-white p-2">
        <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">
          FEE RECEIVING
        </h4>
        {/* Buttons Section */}
        <div className="flex justify-end gap-2">
          <button
            className="bg-blue-600 text-white font-semibold py-2 px-4 hover:bg-blue-700 transition-all duration-300"
            onClick={showModal}
          >
            RECEIVING HISTORY
          </button>
          {/* <button className="bg-blue-600 text-white font-semibold py-2 px-4 hover:bg-blue-700 transition-all duration-300">
          RECEIVING
        </button> */}
        </div>
      </div>

      <div className="w-full flex gap-2">
        {/* Search and Details Section */}
        <div className="bg-white p-2 w-1/2">
          {/* Search Bar */}
          <div className="relative mb-2">
            <input
              type="text"
              className="form-input w-full bg-gray-50 border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Search Student ID"
            />
            <button
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-600 text-white px-4 py-2 hover:bg-blue-700"
              type="button"
            >
              <i className="fas fa-search"></i> Search
            </button>
          </div>

          {/* Student Details */}
          <div className="bg-gray-50 border border-gray-300 p-2">
            <div className="flex justify-between">
              <p className="font-bold text-gray-700">
                Family Id: <span className="font-normal">375</span>
              </p>
              <p className="font-bold text-gray-700">
                Father Name: <span className="font-normal">Mahmood Tufail</span>
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-bold text-gray-700">
                Contact: <span className="font-normal">0300-0000000</span>
              </p>
              <p className="font-bold text-gray-700">
                WhatsApp: <span className="font-normal">0300-0000000</span>
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-bold text-gray-700">
                Address: <span className="font-normal">xyz</span>
              </p>
              <p className="font-bold text-gray-700">
                CNIC: <span className="font-normal">34135-8796744-8</span>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white p-4 w-1/2 text-left">
          <form className="space-y-4">
            {/* Receiving Date */}
            <div className="relative">
              <label
                htmlFor="receivingDate"
                className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-gray-500 peer-placeholder-shown:text-base peer-focus:top-1 peer-focus:text-xs peer-focus:text-blue-500"
              >
                Receiving Date:
              </label>
              <input
                type="text"
                id="receivingDate"
                value={new Date().toLocaleDateString()} // Shows the current date
                readOnly
                className="w-full px-3 py-2 pl-[16vh] border bg-gray-100 text-gray-500 cursor-not-allowed focus:outline-none peer"
              />
            </div>

            {/* Receiving for Month */}
            <div className="relative">
              <label
                htmlFor="receivingMonth"
                className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-gray-500 peer-placeholder-shown:text-base peer-focus:top-1 peer-focus:text-xs peer-focus:text-blue-500"
              >
                Receiving for Month:
              </label>
              <input
                type="month"
                id="receivingMonth"
                required
                className="w-full px-3 py-2 pl-[21vh] border text-gray-500 focus:outline-none peer focus:ring focus:ring-blue-300"
              />
            </div>

            {/* Amount */}
            <div className="relative">
              <label
                htmlFor="amount"
                className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 transition-all duration-200 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-gray-500 peer-placeholder-shown:text-base peer-focus:top-1 peer-focus:text-xs peer-focus:text-blue-500"
              >
                Amount:
              </label>
              <input
                type="number"
                id="amount"
                required
                className="w-full px-3 py-2 pl-[10vh] border focus:outline-none peer focus:ring focus:ring-blue-300"
              />
            </div>

            {/* Receive Button */}
            <div className="text-right">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white hover:bg-blue-700"
              >
                Receive
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Voucher Table */}
      <div className="bg-white p-2">
        <h3 className="text-lg font-semibold bg-themeColor1 text-white py-2 px-4">
          Voucher Details
        </h3>
        <div className="overflow-x-auto mt-2 border border-gray-300">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="text-center text-sm text-black font-semibold uppercase tracking-wider">
                <th className="px-4 py-3">Student</th>
                <th className="px-4 py-3">Tuition Fee</th>
                <th className="px-4 py-3">Discount</th>
                <th className="px-4 py-3">Arrears</th>
                <th className="px-4 py-3">Stationary</th>
                <th className="px-4 py-3">Conveyance</th>
                <th className="px-4 py-3">Late Fee Fine</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr className="text-center text-black">
                <td className="px-4 py-3">Muhammad Feroz</td>
                <td className="px-4 py-3">100158</td>
                <td className="px-4 py-3">100000</td>
                <td className="px-4 py-3">5679</td>
                <td className="px-4 py-3">0</td>
                <td className="px-4 py-3">0</td>
                <td className="px-4 py-3">0</td>
              </tr>
            </tbody>
            <tfoot>
              <tr className="text-center font-semibold">
                <td className="px-4 py-3">Total Dues: 100158</td>
                <td className="px-4 py-3">After Due Date: 100558</td>
                <td className="px-4 py-3">Due Date: 25-Jan-2025</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      {isModalVisible && <FeeReceivingHistoryModal handleClose={handleClose} />}
    </div>
  );
};

export default FeeReceiving;
