import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const AttendanceGraph = () => {
  const data = [
    { name: "Present", value: 400, color: "#4CAF50" },
    { name: "Absent", value: 50, color: "#F44336" },
    { name: "Late", value: 25, color: "#FFC107" },
    { name: "Leave", value: 75, color: "#2196F3" },
  ];

  return (
    <div className="w-full flex flex-col px-6 py-5 bg-white">
      <h1 className="font-semibold text-lg mb-4 text-start">
        Attendance Overview
      </h1>
      <div className="flex flex-col items-center">
        {/* Pie Chart */}
        <PieChart width={250} height={250}>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={70} // Adjust ring thickness
            outerRadius={120} // Outer edge of the ring
            fill="#8884d8"
            labelLine={false}
            isAnimationActive={false} // Disable animation
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>

        {/* Legend in Two Rows */}
        <div className="mt-6 w-full grid grid-cols-2 gap-y-4">
          {data.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between space-x-2 gap-4 px-2"
            >
              <div className="flex items-center gap-2">
                <div
                  className="w-3 h-3 rounded-full"
                  style={{ backgroundColor: item.color }}
                ></div>
                <span className="text-sm">{item.name}</span>
              </div>
              <span className="font-semibold text-sm">{item.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AttendanceGraph;
