import React from 'react';

const BirthdayCard = () => {
  return (
    <div className="bg-white p-4 flex justify-between items-center w-full">
      <div className='flex flex-col'>
      <div className="text-left flex items-center gap-2">
        <p className="text-2xl font-bold">151</p>
        <p className="">Students</p>
      </div>
      <div className="text-left flex items-center gap-2">
        <p className="text-2xl font-bold">51</p>
        <p className="">Teachers</p>
      </div>
      </div>
      <div className="flex justify-center items-center bg-gradient-to-r from-teal-400 to-blue-400 text-white rounded-full w-[50px] h-[50px] p-2">
        <i className="fa-solid fa-cake-candles text-2xl"></i>
      </div>
    </div>
  );
};

export default BirthdayCard;
