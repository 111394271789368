import React from 'react';
import LoginComponent from './LoginComponent';

const LoginScreen = () => {
  return (
    <div
      className="flex flex-col min-h-screen font-customFont relative"
     
    >
      {/* Blur Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-white/10 to-blue-100/90 backdrop-blur-sm"></div>

      {/* Main Content */}
      <div className="relative z-10 flex flex-col lg:flex-row items-center justify-between flex-1 px-8 lg:px-24 py-12 lg:py-16 space-y-10 lg:space-y-0 lg:space-x-12">
        {/* Left Section: Welcome Message */}
        <div className="lg:w-1/2 space-y-6 text-center lg:text-left">
          <h1 className="text-3xl lg:text-6xl font-bold text-gray-800 leading-snug">
            Welcome to <span className="text-themeColor1">EduConnect</span>
          </h1>
          <p className="text-md lg:text-lg text-gray-600">
            Revolutionize your school management with our intuitive, all-in-one platform. Manage students, teachers, and operations effortlessly.
          </p>
        </div>

        {/* Right Section: Login Form */}
        <div className="lg:w-1/2 lg:p-12">
          
          <LoginComponent />
       
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
