import React from 'react';

const ActiveStaff = () => {
  return (
    <div className="bg-white p-4 flex justify-between items-center w-full">
      <div className="text-left">
        <p className="text-2xl font-bold m-0">21</p>
        <p className="m-0">Active Staff</p>
        <p className="m-0">Total Staff: 21</p>
      </div>
      <div className="bg-gradient-to-r from-teal-400 to-blue-500 text-white rounded-full flex justify-center items-center w-12 h-12">
        <i className="fa-solid fa-user-graduate text-xl"></i>
      </div>
    </div>
  );
};

export default ActiveStaff;
