import React from "react";

const EventsCard = () => {
  const eventsList = [
    {
      title: "Independence Day",
      date: "7/11/2024",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed consectetur varius metus, ut ullamcorper risus viverra in.",
    },
    {
      title: "Labor Day",
      date: "9/2/2024",
      description: "Praesent commodo cursus magna, vel scelerisque nisl consectetur et.",
    },
    {
      title: "Thanksgiving",
      date: "11/28/2024",
      description: "Nullam quis risus eget urna mollis ornare vel eu leo.",
    },
    {
      title: "Christmas Day",
      date: "12/25/2024",
      description: "Donec ullamcorper nulla non metus auctor fringilla.",
    },
  ];

  const daysInMonth = 30; // For simplicity, using 30 days here. Adjust based on month/year.

  const renderCalendar = () => {
    const calendar = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const event = eventsList.find((event) => {
        const eventDate = new Date(event.date);
        return eventDate.getDate() === i;
      });
      calendar.push(
        <div
          key={i}
          className="relative flex flex-col items-center justify-center border p-2 border-gray-200 bg-gray-50 hover:bg-gray-100"
        >
          <span className="text-sm font-semibold text-gray-700">{i}</span>
          {event && (
            <div className="absolute bottom-1 p-1 text-xs text-white bg-blue-500 rounded-full shadow-md">
              {event.title}
            </div>
          )}
          {/* Tooltip for Event Description */}
          {event && (
            <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 w-max px-3 py-2 bg-black text-white text-xs rounded-md opacity-0 hover:opacity-100 transition-opacity duration-200">
              <p>{event.description}</p>
            </div>
          )}
        </div>
      );
    }
    return calendar;
  };

  return (
    <div className="p-4 bg-white overflow-hidden">
      <h5 className="text-center font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-700 py-3">
        Upcoming Events Calendar
      </h5>
      <div className="w-full">
        <div className="grid grid-cols-7 gap-2 mt-4 max-w-full mx-auto">
          {/* Render Days of the Week */}
          <div className="font-semibold text-gray-700">Sun</div>
          <div className="font-semibold text-gray-700">Mon</div>
          <div className="font-semibold text-gray-700">Tue</div>
          <div className="font-semibold text-gray-700">Wed</div>
          <div className="font-semibold text-gray-700">Thu</div>
          <div className="font-semibold text-gray-700">Fri</div>
          <div className="font-semibold text-gray-700">Sat</div>

          {/* Render Calendar Days */}
          {renderCalendar()}
        </div>
      </div>
    </div>
  );
};

export default EventsCard;
