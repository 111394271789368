import React, { useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
function AddStudentForm({ handleEdit, setSelectedClassId }) {
  const navigate = useNavigate();
  const { studentId } = useParams();
  const [IsEdit, setIsEdit] = useState(false);
  const initialFormData = {
    studentId: null,
    admYearSessionId: 0,
    studentName: null,
    familyId: 0,
    regNo: 0,
    gender: null,
    religion: null,
    bFormNumber: null,
    medicalProblem: null,
    bloodGroup: null,
    studentMobileNo: null,
    birthDate: null,
    admDate: null,
    subjectOptional: null,
    fatherName: null,
    fatherCnic: null,
    fatherMobile1: null,
    fatherMobile2: null,
    occuption: null,
    whatsAppNo: null,
    guardianName: null,
    guardianMobileNo: null,
    address1: null,
    address2: null,
    caste: null,
    preSchoolName: null,
    preSchoolAddress: null,
    prePassingClassId: null,
    prePassingMonthYear: null,
    prePassingPercentage: null,
    admClassId: 0,
    admSectionId: 0,
    admMediumId: 0,
    remarks: null,
    strength: 0,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [classes, setClasses] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [genders, setGenders] = useState([]);
  const [religions, setReligions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [filteredSections, setFilteredSections] = useState([]);
  const [prevFamilyId, setPrevFamilyId] = useState(null);
  useEffect(() => {
    fetchLookups();
    if (studentId) {
      setIsEdit(true);
      fetchStudentById(studentId);
    }
  }, [studentId]);

  const fetchStudentById = async (studentId) => {
    try {
      const response = await axios.get(
        `https://dotnet.rapidmis.com/studentbyid/${studentId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (response.data) {
        const studentData = response.data;

        setFormData(studentData);
        setSelectedClassId(studentData.admClassId);
        const selectedClass = classes.find(
          (cls) => cls.classId === studentData.admClassId
        );
        if (selectedClass) {
          setFilteredSections(selectedClass.sectionList);
        }
      }
    } catch (error) {
      console.error("Error fetching student data:", error);
      toast.error("Failed to fetch student data.");
    }
  };

  const fetchFamilyData = async (familyId) => {
    if (familyId !== prevFamilyId) {
      setFormData((prevData) => ({
        ...initialFormData,
        familyId: prevData.familyId,
      }));
      setPrevFamilyId(familyId);
    }
    try {
      const response = await axios.get(
        `https://dotnet.rapidmis.com/studentbyfamilyid/${familyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      const familyData = response.data;

      if (familyData) {
        setFormData((prevData) => ({
          ...prevData,
          fatherName: familyData.fatherName || "",
          fatherMobile1: familyData.fatherMobile1 || "",
          fatherMobile2: familyData.fatherMobile2 || "",
          fatherCnic: familyData.fatherCnic || "",
          whatsAppNo: familyData.whatsAppNo || "",
          fatherMonthlyIncome: familyData.fatherMonthlyIncome || "",
          occupation: familyData.occupation || "",
          address1: familyData.address1 || "",
          address2: familyData.address2 || "",
          guardianName: familyData.guardianName || "",
          guardianMobileNo: familyData.guardianMobileNo || "",
          caste: familyData.caste || "",
          strength: familyData.strength || "",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          fatherName: "",
          fatherMobile1: "",
          fatherMobile2: "",
          fatherCnic: "",
          whatsAppNo: "",
          fatherMonthlyIncome: "",
          occupation: "",
          address1: "",
          address2: "",
          guardianName: "",
          guardianMobileNo: "",
          caste: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching family data:", error);
    }
  };

  const fetchLookups = async () => {
    try {
      const response = await axios.get(
        "https://dotnet.rapidmis.com/studentlookup",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      const data = response.data;
      setGenders(data.genderList);
      setReligions(data.religionList);
      setClasses(data.classesWithSessionList);
      setMediums(data.mediumList);
      setSessions(data.yearSessionList);
    } catch (error) {
      console.error("Error fetching lookup data:", error);
      toast.error("Failed to fetch lookup data.");
    }
  };

  let familyIdTimeout;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "admClassId") {
      const selectedClassId = parseInt(value);
      const selectedClass = classes.find(
        (cls) => cls.classId === selectedClassId
      );
      setFilteredSections(selectedClass ? selectedClass.sectionList : []);
    }
    if (name === "familyId") {
      clearTimeout(familyIdTimeout);
      familyIdTimeout = setTimeout(() => {
        if (value) {
          fetchFamilyData(value);
        }
      }, 500);
    }
  };

  const handleFamilyIdBlur = (e) => {
    const familyId = e.target.value;
    if (familyId) {
      fetchFamilyData(familyId);
    }
  };
  const handleFamilyIdKeyDown = (e) => {
    if (e.key === "Enter") {
      const familyId = e.target.value;
      if (familyId) {
        fetchFamilyData(familyId);
      }
    }
  };
  const validate = () => {
    let errors = {};
    if (!formData.admYearSessionId)
      errors.admYearSessionId = "Year Session name is required";
    if (!formData.studentName) errors.studentName = "Student Name is required";
    if (!formData.familyId) errors.familyId = "Family Id is required";
    if (!formData.regNo) errors.regNo = "Reg No is required";
    if (!formData.gender) errors.gender = "Gender is required";
    if (!formData.religion) errors.religion = "Religion is required";
    if (!formData.admDate) errors.admDate = "Admission Date is required";
    if (!formData.birthDate) errors.birthDate = "Birth Date is required";
    if (!formData.fatherName) errors.fatherName = "Father Name is required";
    if (!formData.fatherMobile1)
      errors.fatherMobile1 = "Father Mobile No is required";
    if (!formData.fatherMobile2) errors.fatherMobile2 = "Sms No is required";
    if (!formData.whatsAppNo) errors.whatsAppNo = "WhatsApp No is required";
    if (!formData.address1) errors.address1 = "Home Address is required";
    if (!formData.address2) errors.address2 = "Street Address is required";
    if (!formData.admClassId) errors.admClassId = "Class is required";
    if (!formData.admSectionId) errors.admSectionId = "Section is required";
    if (!formData.admMediumId) errors.admMediumId = "Medium is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const btntype = window.event.submitter.value;
    const formToSubmit = {
      ...formData,
      familyId: parseInt(formData.familyId, 10),
    };
    if (!validate()) {
      return;
    }

    try {
      let response;
      if (IsEdit) {
        response = await axios.put(
          `https://dotnet.rapidmis.com/studentupdate/${studentId}`,
          formToSubmit,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
      } else {
        response = await axios.post(
          "https://dotnet.rapidmis.com/studentcreate",
          formToSubmit,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
      }

      if (response.status === 200) {
        toast.success("Student saved successfully!");
        if (btntype === "btnlist") {
          setTimeout(() => {
            navigate("/panel/viewStudentList");
          }, 2000);
        } else if (btntype === "btnstand" && !IsEdit) {
          navigate(`/panel/updateStudent/${response.data}`);
        }
      } else {
        toast.error(`Failed to save student: ${response.statusText}`);
      }
    } catch (error) {
      toast.error("An error occurred while saving the student.");
      console.error("Error creating/updating student", error);
    }
  };

  return (
    <div className="" style={{ fontFamily: "Roboto, sans-serif" }}>

      <form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
        <h3 className="py-1 bg-themeColor1 text-white text-lg font-semibold">
          STUDENT PROFILE
        </h3>
        <fieldset className="">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <div className="">
              <InputFloating
                label="FamilyId*"
                type="number"
                name="familyId"
                value={formData.familyId || ""}
                error={formErrors.familyId}
                onChange={handleChange}
                onBlur={handleFamilyIdBlur}
                onKeyDown={handleFamilyIdKeyDown}
              />
              <InputFloating
                label="Registration No*"
                type="number"
                name="regNo"
                value={formData.regNo}
                error={formErrors.regNo}
                onChange={handleChange}
              />
              <InputFloating
                label="Student Name*"
                type="text"
                name="studentName"
                value={formData.studentName}
                error={formErrors.studentName}
                onChange={handleChange}
              />
              <InputFloating
                label="Date of Birth*"
                type="date"
                name="birthDate"
                value={formData.birthDate}
                error={formErrors.birthDate}
                onChange={handleChange}
                max={new Date().toISOString().split("T")[0]}
              />
              <InputFloating
                label="B-Form-Number"
                type="number"
                name="bFormNumber"
                value={formData.bFormNumber}
                onChange={handleChange}
              />
              <InputFloating
                label="Student Mobile No"
                type="text"
                name="studentMobileNo"
                value={formData.studentMobileNo}
                onChange={handleChange}
              />
            </div>
            <div className="">
              <div className="relative mb-4">
                <div
                  name="strength"
                  value={formData.strength}
                  className={`block h-12 w-full px-2.5 pb-2.5 text-left pt-4 text-sm text-gray-900 bg-transparent border appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer`}
                  placeholder=" "
                >
                  {formData.strength}
                </div>
                <label className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor">
                  Strength
                </label>
              </div>
              <SelectWithIcon
                label="Select Gender*"
                options={
                  genders && genders.length > 0
                    ? genders.map((s) => ({
                        label: s.genderName,
                        value: s.genderId,
                      }))
                    : []
                }
                name="gender"
                value={formData.gender}
                error={formErrors.gender}
                onChange={handleChange}
              />
              <SelectWithIcon
                label="Select Religion*"
                options={
                  religions && religions.length > 0
                    ? religions.map((s) => ({
                        label: s.religionName,
                        value: s.religionId,
                      }))
                    : []
                }
                name="religion"
                value={formData.religion}
                error={formErrors.religion}
                onChange={handleChange}
              />
              <SelectWithIcon
                label="Select Blood Group"
                options={[
                  { label: "A+", value: "A+" },
                  { label: "A-", value: "A-" },
                  { label: "B+", value: "B+" },
                  { label: "B-", value: "B-" },
                  { label: "AB+", value: "AB+" },
                  { label: "AB-", value: "AB-" },
                  { label: "O+", value: "O+" },
                  { label: "O-", value: "O-" },
                ]}
                name="bloodGroup"
                onChange={handleChange}
                value={formData.bloodGroup}
              />
              <InputFloating
                label="Medical Problem"
                type="text"
                value={formData.medicalProblem}
                name="medicalProblem"
                onChange={handleChange}
              />
            </div>
            <div className="">
              <div className="flex flex-col items-center">
                <figure className="mb-4">
                  <img
                    className="rounded-full w-40 h-40 object-cover"
                    src="https://bootdey.com/img/Content/avatar/avatar1.png"
                    alt="avatar"
                  />
                </figure>
                <input
                  type="file"
                  className="file-uploader mb-4  rounded-lg px-4 py-2"
                  accept=".jpg, .jpeg, .png"
                />
              </div>
            </div>
          </div>
        </fieldset>
        <h3 className="py-1 bg-themeColor1 text-white text-lg font-semibold">
          GUARDIAN'S PROFILE
        </h3>
        <fieldset className="">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <div className="">
              <InputFloating
                label="Father Name*"
                type="text"
                name="fatherName"
                value={formData.fatherName}
                error={formErrors.fatherName}
                onChange={handleChange}
              />
              <InputFloating
                label="Mobile No*"
                type="text"
                name="fatherMobile1"
                value={formData.fatherMobile1}
                error={formErrors.fatherMobile1}
                onChange={handleChange}
              />
              <InputFloating
                label="Father CNIC"
                type="text"
                name="fatherCnic"
                value={formData.fatherCnic}
                onChange={handleChange}
              />
              <InputFloating
                label="House No*"
                type="text"
                name="address1"
                value={formData.address1}
                error={formErrors.address1}
                onChange={handleChange}
              />
            </div>
            <div className="">
              <InputFloating
                label="Monthly Income"
                type="text"
                name="monthlyIncome"
                value={formData.monthlyIncome}
                onChange={handleChange}
              />
              <InputFloating
                label="WhatsApp No*"
                type="text"
                name="whatsAppNo"
                value={formData.whatsAppNo}
                error={formErrors.whatsAppNo}
                onChange={handleChange}
              />
              <InputFloating
                label="Sms No*"
                type="text"
                name="fatherMobile2"
                value={formData.fatherMobile2}
                error={formErrors.fatherMobile2}
                onChange={handleChange}
              />
              <InputFloating
                label="Caste"
                type="text"
                name="caste"
                value={formData.caste}
                onChange={handleChange}
              />
            </div>
            <div className="">
              <InputFloating
                label="Occupation"
                type="text"
                name="occupation"
                value={formData.occuption}
                onChange={handleChange}
              />
              <InputFloating
                label="Guardian Name"
                type="text"
                name="guardianName"
                value={formData.guardianName}
                onChange={handleChange}
              />
              <InputFloating
                label="Guadian Mobile No"
                type="text"
                name="guardianMobileNo"
                value={formData.guardianMobileNo}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-1">
            <InputFloating
              label="Street*"
              type="text"
              name="address2"
              value={formData.address2}
              error={formErrors.address2}
              onChange={handleChange}
            />
          </div>
        </fieldset>
        <h3 className="py-1 bg-themeColor1 text-white text-lg font-semibold">
          PREVIOUS INSTITUTE INFORMATION
        </h3>
        <fieldset className="">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <InputFloating
              label="Pre School Name"
              type="text"
              value={formData.preSchoolName}
              name="preSchoolName"
              onChange={handleChange}
            />
            <SelectWithIcon
              label="Select Class"
              value={formData.prePassingClassId}
              options={
                classes?.length > 0
                  ? classes.map((c) => ({
                      label: c.classDisplayName,
                      value: c.classId,
                    }))
                  : []
              }
              name="prePassingClassId"
              onChange={handleChange}
            />
            <div className="grid grid-cols-2 gap-2">
              <InputFloating
                label="Pre School Passing %"
                value={formData.prePassingPercentage}
                type="text"
                name="prePassingPercentage"
                maxLength="3"
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  if (value.length <= 3) {
                    handleChange({
                      target: { name: e.target.name, value: value },
                    });
                  }
                }}
              />
              <InputFloating
                label="Passing Month/Year"
                value={formData.prePassingMonthYear}
                type="month"
                name="prePassingMonthYear"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-1">
            <InputFloating
              label="Pre School Address"
              value={formData.preSchoolAddress}
              type="text"
              name="preSchoolAddress"
              onChange={handleChange}
            />
          </div>
        </fieldset>
        <h3 className="py-1 bg-themeColor1 text-white text-lg font-semibold">
          ADMISSION INFORMATION
        </h3>
        <fieldset className="">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <div className="">
              <SelectWithIcon
                label="Select Class*"
                options={
                  classes && classes.length > 0
                    ? classes.map((c) => ({
                        label: c.classDisplayName,
                        value: c.classId,
                      }))
                    : []
                }
                name="admClassId"
                error={formErrors.admClassId}
                onChange={handleChange}
                value={formData.admClassId}
              />
              <SelectWithIcon
                label="Select Section*"
                options={
                  filteredSections.length > 0
                    ? filteredSections.map((s) => ({
                        label: s.sectionDisplayName,
                        value: s.sectionId,
                      }))
                    : []
                }
                name="admSectionId"
                error={formErrors.admSectionId}
                onChange={handleChange}
                value={formData.admSectionId}
              />
            </div>
            <div className="">
              <SelectWithIcon
                label="Select Medium*"
                options={
                  mediums && mediums.length > 0
                    ? mediums.map((m) => ({
                        label: m.displayName,
                        value: m.mediumId,
                      }))
                    : []
                }
                name="admMediumId"
                error={formErrors.admMediumId}
                onChange={handleChange}
                value={formData.admMediumId}
              />
              <SelectWithIcon
                label="Select Session*"
                options={
                  sessions && sessions.length > 0
                    ? sessions.map((s) => ({
                        label: s.yearSessionName,
                        value: s.yearSessionId,
                      }))
                    : []
                }
                name="admYearSessionId"
                error={formErrors.admYearSessionId}
                onChange={handleChange}
                value={formData.admYearSessionId}
              />
            </div>
            <div className="">
              <InputFloating
                label="Admission Date*"
                type="date"
                value={formData.admDate}
                name="admDate"
                error={formErrors.admDate}
                min={new Date().toISOString().split("T")[0]} // Set min date to today
                onChange={handleChange}
              />

              <InputFloating
                label="Optional Subjects"
                type="text"
                value={formData.optinalSubjects}
                name="optinalSubjects"
                error={formErrors.subjectOptional}
                onChange={handleChange}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="">
          <div className="flex justify-end mb-2 mt-2 gap-2">
            <button
              value="btnstand"
              className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600"
            >
              <i className="fa-regular fa-floppy-disk me-1"></i>{" "}
              {IsEdit ? "Update" : "Save & Edit"}
            </button>

            <button
              value="btnlist"
              className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600"
            >
              <i className="fa-regular fa-floppy-disk me-1"></i>{" "}
              {IsEdit ? "Update & Close" : "Save & Close"}
            </button>
          </div>
        </fieldset>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </form>
    </div>
  );
}
const InputFloating = ({
  label,
  type,
  name,
  value,
  onChange,
  error,
  onBlur,
  onKeyDown,
  min,
  max,
}) => {
  return (
    <div className="relative mb-4">
      <input
        type={type}
        id={name}
        name={name}
        value={value !== null ? value : ""}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        min={min}
        max={max}
        className={`block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border ${
          error ? "border-red-500" : "border-gray-300"
        } appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer`}
        placeholder=" "
      />
      <label
        htmlFor={name}
        className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor"
      >
        {label}
      </label>
    </div>
  );
};

const SelectWithIcon = ({
  label,
  options = [],
  name,
  value,
  onChange,
  error,
}) => {
  return (
    <div className="relative mb-4">
      <MenuOutlined className="absolute left-3 top-0 bottom-0 pointer-events-none" />
      <select
        className={`block w-full py-3.5 pl-10 pr-3 text-gray-900 bg-transparent border ${
          error ? "border-red-500" : "border-gray-300"
        } focus:outline-none focus:ring-2 focus:ring-blue-500`}
        name={name}
        onChange={onChange}
        value={value !== null && value !== undefined ? value : ""}
      >
        <option value="">{label}</option>
        {options && options.length > 0 ? (
          options.map((option) =>
            option && option.value !== undefined ? (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ) : null
          )
        ) : (
          <option disabled>No options available</option>
        )}
      </select>
    </div>
  );
};

export default AddStudentForm;
