import axios from "axios";
import React, { useEffect, useState } from "react";

function NewStudentFeeManagement({ selectedClassId }) {
  const [feeHeadList, setFeeHeadList] = useState([]);
  const [feeAssignmentList, setFeeAssignmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://dotnet.rapidmis.com/feeconcessionbyclass?ClassId=${selectedClassId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        const { feeAssignmentList = [] } = response.data || {};
        setFeeAssignmentList(feeAssignmentList[0]?.feeHeadAmountList || []);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedClassId]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://dotnet.rapidmis.com/feeconcessionlookup", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { feeHeadList = [] } = response.data || {};
        setFeeHeadList(feeHeadList);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const calculateReceivableFee = (totalFee, discount) => {
    return totalFee - discount;
  };

  return (
    <div>
      <form className="space-y-6">
        <fieldset>
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-200">
                <th className="border-b border-gray-300 px-2 py-3 text-themeColor">
                  Fee Type
                </th>
                <th className="border-b border-gray-300 px-2 py-3 text-themeColor">
                  Total Fee
                </th>
                <th className="border-b border-gray-300 px-2 py-3 text-themeColor">
                  Discount
                </th>
                <th className="border-b border-gray-300 px-2 py-3 text-themeColor">
                  Receivable Fee
                </th>
              </tr>
            </thead>
            <tbody>
              {feeHeadList.map((fee, index) => {
                const assignment = feeAssignmentList.find(
                  (item) => item.feeHeadId === fee.feeHeadId
                );
                const totalFee = assignment?.amount || 0;
                const discount = fee.discount || "";
                const receivableFee = calculateReceivableFee(totalFee, discount);

                return (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="border border-gray-200 px-4 py-2">
                      {fee.feeHeadName}
                    </td>
                    <td className="border border-gray-200 px-4 py-2">{totalFee}</td>
                    <td className="border border-gray-200 px-4 py-2">
                      <input
                        type="number"
                        value={discount}
                        className="w-full border border-gray-300 rounded-md p-2 text-center focus:outline-none focus:ring-2 focus:ring-themeColor"
                        onChange={(e) => {
                          const newDiscount = Number(e.target.value);

                          // Ensure discount is not greater than totalFee and is not negative
                          if (newDiscount <= totalFee && newDiscount >= 0) {
                            setFeeHeadList((prev) =>
                              prev.map((f, i) =>
                                i === index ? { ...f, discount: newDiscount } : f
                              )
                            );
                          }
                        }}
                      />
                    </td>
                    <td className="border border-gray-200 px-4 py-2">{receivableFee}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </fieldset>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-themeColor text-white py-2 px-4 transition duration-300"
          >
            Save Profile
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewStudentFeeManagement;
