import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import {
  TeamOutlined,
  SettingOutlined,
  DashboardOutlined,
  BarChartOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingColumns,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const { SubMenu } = Menu;

const Sidebar = ({ onNavigation }) => {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const userRoleData = JSON.parse(localStorage.getItem("employeeRoleData"));

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location]);

  const handleNavigation = (page) => {
    onNavigation(page);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };


  const isModuleAvailable = (moduleId) => {
    if (!userRoleData || !userRoleData.rolePermission || !userRoleData.rolePermission.moduleList) return false;
    return userRoleData.rolePermission.moduleList.some((module) => module.moduleId === moduleId);
  };
  
  const isFeatureAvailable = (moduleId, featureId) => {
    if (!userRoleData || !userRoleData.rolePermission || !userRoleData.rolePermission.moduleList) return false;
    return userRoleData.rolePermission.moduleList.some(
      (module) =>
        module.moduleId === moduleId &&
        module.featureList.some((feature) => feature.featureId === featureId)
    );
  };
  
  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={selectedKeys}
      onClick={({ key }) => handleNavigation(key)}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={{ paddingTop: 0,fontFamily: 'Roboto, sans-serif' }}
    >
      <h1 className="text-lg text-white mt-3">
        <strong>CAMPUS PANEL</strong>
      </h1>
      <Menu.Item key="/panel/dashboardCampus" icon={<DashboardOutlined />}>
        <div>Dashboard</div>
      </Menu.Item>
      {isModuleAvailable(3) && (
        <SubMenu
          key="student"
          icon={<TeamOutlined />}
          title={
            <span style={{ textAlign: "left", display: "block" }}>
              Student Management
            </span>
          }
        >
          {isFeatureAvailable(3 , 301) && (
            <Menu.Item
              key="/panel/viewStudentList"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div>Students List</div>
            </Menu.Item>
          )}
          {isFeatureAvailable(3 , 303) && (
            <Menu.Item
              key="/panel/familySummary"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div>Family Summary</div>
            </Menu.Item>
          )}
          {isFeatureAvailable(3 , 304) && (
            <Menu.Item
              key="/panel/attendence"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Attendance</div>
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {isModuleAvailable(2) && (
        <SubMenu
          key="staff"
          icon={<FontAwesomeIcon icon={faGraduationCap} />}
          title={
            <span style={{ textAlign: "left", display: "block" }}>
              Staff Management
            </span>
          }
        >
          {isFeatureAvailable(2 , 203) && (
            <Menu.Item
              key="/panel/viewStaff"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Staff List</div>
            </Menu.Item>
          )}
          {isFeatureAvailable(2 , 205) && (
            <Menu.Item
              key="/panel/staffAttendence"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Staff Attendance</div>
            </Menu.Item>
          )}
          <Menu.Item
            key="/panel/staffResult"
            style={{ paddingLeft: "30px" }}
            icon={<ArrowRightOutlined />}
          >
            <div className="pull-left">Staff Result</div>
          </Menu.Item>
          {isFeatureAvailable(2 , 201) && (
            <Menu.Item
              key="/panel/departmentList"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Department</div>
            </Menu.Item>
          )}
          {isFeatureAvailable(2 , 202) && (
            <Menu.Item
              key="/panel/designationList"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Designation</div>
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {isModuleAvailable(4) && (
        <SubMenu
          key="finance"
          icon={<FontAwesomeIcon icon={faBuildingColumns} />}
          title={
            <span style={{ textAlign: "left", display: "block" }}>
              Fee Management
            </span>
          }
        >
          {isFeatureAvailable(4 , 401) && (
            <Menu.Item
              key="/panel/feeHead"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Fee Head</div>
            </Menu.Item>
          )}
          {isFeatureAvailable(4 , 402) && (
            <Menu.Item
              key="/panel/assignFee"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Fee Assignment</div>
            </Menu.Item>
          )}
          {isFeatureAvailable(4 , 403) && (
            <Menu.Item
              key="/panel/feeConcessions"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Fee Concessions</div>
            </Menu.Item>
          )}
          <Menu.Item
            key="/panel/feeChallan"
            style={{ paddingLeft: "30px" }}
            icon={<ArrowRightOutlined />}
          >
            <div className="pull-left">Fee Challan</div>
          </Menu.Item>
          <Menu.Item
            key="/panel/feeReceiving"
            style={{ paddingLeft: "30px" }}
            icon={<ArrowRightOutlined />}
          >
            <div className="pull-left">Fee Receiving</div>
          </Menu.Item>

          <Menu.Item
            key="/panel/financePage"
            style={{ paddingLeft: "30px" }}
            icon={<ArrowRightOutlined />}
          >
            <div className="pull-left">Finance Page</div>
          </Menu.Item>
        </SubMenu>
      )}

      <SubMenu
        key="results"
        icon={<BarChartOutlined />}
        title={
          <span style={{ textAlign: "left", display: "block" }}>
            Exam Management
          </span>
        }
      >
        <Menu.Item
          key="/panel/datesheet"
          style={{ paddingLeft: "30px" }}
          icon={<ArrowRightOutlined />}
        >
          <div className="pull-left">Date Sheet</div>
        </Menu.Item>
        <Menu.Item
          key="/panel/addresults"
          style={{ paddingLeft: "30px" }}
          icon={<ArrowRightOutlined />}
        >
          <div className="pull-left">Results</div>
        </Menu.Item>
        <Menu.Item
          key="/panel/viewResult"
          style={{ paddingLeft: "30px" }}
          icon={<ArrowRightOutlined />}
        >
          <div className="pull-left">View Results</div>
        </Menu.Item>
      </SubMenu>
      {isModuleAvailable(1) && (
        <SubMenu
          key="settings"
          icon={<SettingOutlined />}
          title={
            <span style={{ textAlign: "left", display: "block" }}>
              Settings
            </span>
          }
        >
          {isFeatureAvailable(1 , 101) && ( // Assuming featureId for Classes is 101
            <Menu.Item
              key="/panel/classList"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Classes</div>
            </Menu.Item>
          )}
          {isFeatureAvailable(1 , 102) && ( // Assuming featureId for Sections is 102
            <Menu.Item
              key="/panel/sectionList"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Sections</div>
            </Menu.Item>
          )}
          {isFeatureAvailable(1 , 103) && ( // Assuming featureId for Mediums is 103
            <Menu.Item
              key="/panel/MediumList"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Mediums</div>
            </Menu.Item>
          )}
          {isFeatureAvailable(1 , 104) && (
            <Menu.Item
              key="/panel/rolePermission"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Role Permissions</div>
            </Menu.Item>
          )}
          {isFeatureAvailable(1 , 105) && (
            <Menu.Item
              key="/panel/campusList"
              style={{ paddingLeft: "30px" }}
              icon={<ArrowRightOutlined />}
            >
              <div className="pull-left">Campuses</div>
            </Menu.Item>
          )}
        </SubMenu>
      )}
    </Menu>
  );
};

export default Sidebar;
