import React, { useEffect, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';

function AddStaff() {
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [isActiveRole, setIsActiveRole] = useState(false);
  const handleToggleChange = () => {
    const newIsActiveRole = !isActiveRole;
    setIsActiveRole(newIsActiveRole);
  
    // Update formData with the new value for isAllowedAuthentication
    setFormData((prevData) => ({
      ...prevData,
      isAllowedAuthentication: newIsActiveRole ? true : false, // Set true when active, false when inactive
    }));
  };
  
  const initialFormData = {
    employeeId: null,
    yearSessionId: 0,
    employeeName: null,
    fatherOrHusbandName: null,
    employeeCnic: null,
    mobile1: null,
    mobile2: null,
    whatsAppNo: null,
    gender: null,
    religion: null,
    caste: null,
    emailAddress: null,
    bloodGroup: null,
    medicalProblem: null,
    birthDate: null,
    joiningDate: null,
    presentAddress: null,
    permanentAddress: null,
    rolePermissionId: 0,
    designationId: 0,
    departmentId: 0,
    isAllowedAuthentication: null,
    employeeEducationList: [],
    employeeWorkExperienceList: [],
  };
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [genders, setGenders] = useState([]);
  const [religions, setReligions] = useState([]);
  const [yearSessionList, setYearSessionList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [rolePermissionList, setRolePermissionList] = useState([]);
  const [educationFields, setEducationFields] = useState([{ degreeName: '', instituteName: '', yearOfPassing: '', percentage: '', grade:'' }]);
  const [experienceFields, setExperienceFields] = useState([{ organizationName: '', designationName: '', fromDate: '', toDate: '' }]);
  const validate = () => {
    let errors = {};
    if (!formData.employeeName) errors.employeeName = 'Employee Name is required';
    if (!formData.fatherOrHusbandName) errors.employeeName = 'Father/Husband name is required';
    if (!formData.employeeCnic) errors.employeeCnic = 'Employee CNIC is required';
    if (!formData.mobile1) errors.mobile1 = 'Mobile1 is required';
    if (!formData.gender) errors.gender = 'Gender is required';
    if (!formData.religion) errors.religion = 'Religion is required';
    if (!formData.birthDate) errors.birthDate = 'Birth Date is required';
    if (!formData.joiningDate) errors.joiningDate = 'Joining Date is required';
    if (!formData.presentAddress) errors.presentAddress = 'Present Address is required';
    if (!formData.designationId) errors.designationId = 'Designation is required';
    if (!formData.departmentId) errors.departmentId = 'Department is required';
    if (!formData.yearSessionId) errors.yearSessionId = 'Year Session is required';
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (employeeId) {
      setIsEdit(true); // Set edit mode if employeeId exists
      fetchEmployeeById(employeeId); // Fetch employee data
    }
    fetchEmployeeById(); // Fetch form dropdown options
  }, [employeeId]);

  const fetchEmployeeById = async (employeeId) => {
    try {
      const response = await axios.get(`https://dotnet.rapidmis.com/employeebyid/${employeeId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      setFormData(response.data); // Populate form with fetched data
    } catch (error) {
      console.error('Error fetching staff data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the form from reloading the page
    console.log("Submitting form...");
  
    if (!validate()) {
      console.log("Validation failed:", formErrors);
      console.log("Data:", formData);
      return;
    }
  
    try {
      debugger;
      console.log("Form data:", formData); // To see the final formData object before submission
      const url = isEdit
        ? `https://dotnet.rapidmis.com/employeeupdate/${employeeId}`
        : 'https://dotnet.rapidmis.com/employeecreate';
  
      // Use the appropriate HTTP method based on the `isEdit` flag
      const response = isEdit
        ? await axios.put(url, formData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
          })
        : await axios.post(url, formData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
          });
  
      if (response.status === 200) {
        setFormData(initialFormData);
        toast.success("Employee saved successfully!");
        setTimeout(() => {
          navigate("/panel/viewStaff");
        }, 2000);
        setFormData(initialFormData); // Reset form after successful submission
      } else {
        toast.error(`Failed to save employee: ${response.statusText}`);
        console.error("Form submission error:", response);
      }
    } catch (error) {
      toast.error("An error occurred while saving employee.");
      console.error("Error creating employee", error);
    }
  };
  

  useEffect(() => {
    const fetchEmployeeLookup = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          throw new Error("Authentication token is missing");
        }
        
        const response = await axios.get('https://dotnet.rapidmis.com/employeelookup', {
          headers: {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${token}`,
          }
        });
        
        const data = response.data;
        setGenders(data.genderList);
        setReligions(data.religionList);
        setYearSessionList(data.yearSessionList);
        setDepartmentList(data.departmentList);
        setDesignationList(data.designationList);
        setRolePermissionList(data.rolePermissionList);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          console.error('Unauthorized: Check if the token is correct or if the session has expired.');
        }
      }
    };
  
    fetchEmployeeLookup();
  }, []);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("Field:", name, "Value:", value);  // Debugging
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleAddEducationField = () => {
    const updatedEducationFields = [...educationFields, { degreeName: '', instituteName: '', yearOfPassing: '', percentage: '', grade: '' }];
    setEducationFields(updatedEducationFields);
    setFormData({ ...formData, employeeEducationList: updatedEducationFields });
  };

  const handleDeleteEducationField = (index) => {
    const newEducationFields = [...educationFields];
    newEducationFields.splice(index, 1);
    setEducationFields(newEducationFields);
  };

  const handleEducationChange = (index, e) => {
    const { name, value } = e.target;
    const newEducationFields = [...educationFields];
    newEducationFields[index][name] = value;
    setEducationFields(newEducationFields);
  };

  const handleAddExperienceField = () => {
    const updatedExperienceFields = [...experienceFields, { organizationName: '', designationName: '', fromDate: '', toDate: '' }];
    setExperienceFields(updatedExperienceFields);
    setFormData({ ...formData, employeeWorkExperienceList: updatedExperienceFields });
  };
  

  const handleDeleteExperienceField = (index) => {
    const newExperienceFields = [...experienceFields];
    newExperienceFields.splice(index, 1);
    setExperienceFields(newExperienceFields);
  };

  const handleExperienceChange = (index, e) => {
    const { name, value } = e.target;
    const newExperienceFields = [...experienceFields];
    newExperienceFields[index][name] = value;
    setExperienceFields(newExperienceFields);
  };

  return (
    <div className="bg-white p-2" style={{ fontFamily: 'Roboto, sans-serif' }}>
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1 mb-2">{isEdit ? 'UPDATE STAFF' : 'ADD STAFF'}</h4>

      <form className="space-y-6" onSubmit={handleSubmit}>
      <h3 className="py-1 bg-themeColor1 text-white text-lg font-semibold">STAFF PROFILE</h3>
        <fieldset className="">
          <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
            {/* Personal Information */}
            <div>
              <InputFloating label="Enter Name*" type="text" name="employeeName" value={formData.employeeName} onChange={handleInputChange} error={formErrors.employeeName} />
              <InputFloating label="Date of Birth*" type="date" name="birthDate" value={formData.birthDate} onChange={handleInputChange} error={formErrors.birthDate} />
              <InputFloating label="Mobile No 1*" type="text" name="mobile1" value={formData.mobile1} onChange={handleInputChange} error={formErrors.mobile1} />
              <InputFloating label="CNIC No*" type="text" name="employeeCnic" value={formData.employeeCnic} onChange={handleInputChange} error={formErrors.employeeCnic} />
              <InputFloating label="Email" type="email" name="emailAddress" value={formData.emailAddress} onChange={handleInputChange} />
            </div>
            <div>
              <InputFloating label="Father/Husband Name*" type="text" value={formData.fatherOrHusbandName} name="fatherOrHusbandName" onChange={handleInputChange} error={formErrors.fatherOrHusbandName} />
              <InputFloating label="WhatsApp No" type="number" value={formData.whatsAppNo} name="whatsAppNo"onChange={handleInputChange} />
              <InputFloating label="Mobile No 2" type="text" name="mobile2" value={formData.mobile2} onChange={handleInputChange} />
              <InputFloating label="Enter Caste" type="text" name="caste" value={formData.caste} onChange={handleInputChange} />
              <InputFloating label="Medical Problem" type="text" name="medicalProblem" value={formData.medicalProblem} onChange={handleInputChange} />
                
            </div>
            <div className='flex flex-col items-center'>
              <figure className="mb-4">
                <img
                  className="rounded-full w-40 h-40 object-cover"
                  src="https://bootdey.com/img/Content/avatar/avatar1.png"
                  alt="avatar"
                />
              </figure>
              <input type="file" className="file-uploader mb-4  rounded-lg px-4 py-2" accept=".jpg, .jpeg, .png" />
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
            <div className=''>
            <SelectWithIcon label="Select Gender*" name="gender" error={formErrors.gender} options={genders && genders.length > 0 ? genders.map(s => ({ label: s.genderName, value: s.gender })) : []} onChange={(e) => setFormData({ ...formData, gender: e.target.value })} />
            </div>
            <div className=''>
            <SelectWithIcon label="Select Religion*" value={formData.religion} name="religion" error={formErrors.religion} options={religions && religions.length > 0 ? religions.map(s => ({ label: s.religionName, value: s.religion })) : []} onChange={(e) => setFormData({ ...formData, religion: e.target.value })}  />
            </div>
            <div className=''>
            <SelectWithIcon
                 label="Select Blood Group" 
                 name="bloodGroup"
                 value={formData.bloodGroup}
                 onChange={(e) => setFormData({ ...formData, bloddGroup: e.target.value })}
                 options={[
                    { label: "A+", value: "A+" },{ label: "A-", value: "A-" },{ label: "B+", value: "B+" },{ label: "B-", value: "B-" },
                    { label: "AB+", value: "AB+" },{ label: "AB-", value: "AB-" },{ label: "O+", value: "O+" },{ label: "O-", value: "O-" }
                ]} />
            </div>
          </div>
          <InputFloating label="Present Address*" type="text" name="presentAddress" value={formData.presentAddress} onChange={handleInputChange} error={formErrors.presentAddress} />
          <InputFloating label="Permanent Address"  type="text" name="permanentAddress" value={formData.permanentAddress} onChange={handleInputChange} />
        </fieldset>
        {/* Education Section */}
        <h3 className="py-1 bg-themeColor1 text-white text-lg font-semibold">EDUCATION</h3>
        <fieldset className="">
          {educationFields.map((field, index) => (
            <>
            <div key={index} className="grid grid-cols-1 md:grid-cols-5 gap-2">
              <InputFloating label="Degree Title" type="text" name="degreeName" value={field.degreeName} onChange={(e) => handleEducationChange(index, e)} />
              <InputFloating label="Institute" type="text" name="instituteName" value={field.instituteName} onChange={(e) => handleEducationChange(index, e)} />
              <InputFloating label="Year of Passing" type="text" name="yearOfPassing" value={field.yearOfPassing} onChange={(e) => handleEducationChange(index, e)} />
              <InputFloating label="Percentage %" type="number" name="percentage" value={field.percentage} onChange={(e) => handleEducationChange(index, e)} />
              <InputFloating label="Grade" type="text" name="grade" value={field.grade} onChange={(e) => handleEducationChange(index, e)} />
              
            </div>
            <div style={{position:'absolute', right:'1.5rem',marginTop:'-1.6rem'}}>
              {index > 0 && (
                <button
                  type="button"
                  className="text-lg text-red-600"
                  onClick={() => handleDeleteEducationField(index)}
                >
                  <i class="fa-solid fa-circle-xmark"></i>
                </button>
              )}
              </div>
            </>
          ))}
          <div className="flex justify-end mt-4">
          <button
            type="button"
            className="text-2xl text-blue-500 hover:text-blue-600"
            onClick={handleAddEducationField}
          >
            <i class="fa-solid fa-circle-plus"></i>
          </button>
          </div>
        </fieldset>
        {/* Experience Section */}
        <h3 className="py-1 bg-themeColor1 text-white text-lg font-semibold">EXPERIENCE</h3>
        <fieldset className="">
          {experienceFields.map((field, index) => (
            <>
            <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-2 items-center">
              <InputFloating label="Institute" type="text" name="organizationName" value={field.organizationName} onChange={(e) => handleExperienceChange(index, e)} />
              <InputFloating label="Designation" type="text" name="designationName" value={field.designationName} onChange={(e) => handleExperienceChange(index, e)} />
              <InputFloating label="From" type="date" name="fromDate" value={field.fromDate} onChange={(e) => handleExperienceChange(index, e)} />
              <InputFloating label="To" type="date" name="toDate" value={field.toDate} onChange={(e) => handleExperienceChange(index, e)} />
              
              {/* Delete Button */}
              
            </div>
            <div style={{position:'absolute', right:'1.5rem',marginTop:'-1.6rem'}}>
            {index > 0 && (
                <button
                  type="button"
                  className="text-lg text-red-600" 
                  onClick={() => handleDeleteExperienceField(index)}
                >
                  <i class="fa-solid fa-circle-xmark "></i>
                </button>
              )}
            </div>
            </>
          ))}
          {/* Add Button */}
          <div className="flex justify-end mt-5">
            <button
              type="button"
              className="text-2xl text-blue-500 hover:text-blue-600"
              onClick={handleAddExperienceField}
            >
              <i className="fa-solid fa-circle-plus"></i>
            </button>
          </div>
        </fieldset>
        <h3 className="py-1 bg-themeColor1 text-white text-lg font-semibold">JOB INFORMATION</h3>
        <fieldset className="">
        <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
            <div className=''>
            <InputFloating label="Joining Date*" type="date" name="joiningDate" value={formData.joiningDate} onChange={handleInputChange} error={formErrors.joiningDate}  />
            <SelectWithIcon
              label="Select Session*"
              name="yearSessionId"
              value={formData.yearSessionId}
              error={formErrors.yearSessionId}
              onChange={(e) => setFormData({ ...formData, yearSessionId: e.target.value })}  // Add this line
              options={yearSessionList && yearSessionList.length > 0 
                ? yearSessionList.map(s => ({ label: s.yearSessionName, value: s.yearSessionId }))
                : []}
            />
           </div>
            <div className=''>
            <SelectWithIcon label="Select Designation*" name="designationId" onChange={handleInputChange} error={formErrors.designationId} options={designationList && designationList.length > 0 ? designationList.map(s => ({ label: s.designationName, value: s.designationId })) : []}/>
            <SelectWithIcon label="Select Department*" name="departmentId"  value={formData.departmentId} error={formErrors.departmentId} onChange={handleInputChange} options={departmentList && departmentList.length > 0 ? departmentList.map(s => ({ label: s.departmentName, value: s.departmentId })) : []}/>
            </div>
            <div className=''>
              <select
                name="rolePermissionId"
                disabled={!isActiveRole} // Disable dropdown based on isActiveRole state
                onChange={handleInputChange}
                className={`block w-full py-3.5 pl-3 pr-3 mb-4 text-gray-900 bg-transparent border ${!isActiveRole ? 'bg-gray-200' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-blue-500`}
              >
                <option value="">Select Role*</option> {/* Default option */}
                {rolePermissionList && rolePermissionList.length > 0 ? 
                  rolePermissionList.map(s => (
                    <option key={s.rolePermissionId} value={s.rolePermissionId}>
                      {s.rolePermissionName}
                    </option>
                  )) 
                  : null}
              </select>
              
              <div className='flex gap-4 items-center '>
                <label className="text-sm text-themeColor font-semibold block">Add Role</label>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={isActiveRole} // Bind the checkbox to isActiveRole state
                    onChange={handleToggleChange} // Update the toggle change handler
                  />
                  <div className={`relative w-9 h-5 ${isActiveRole ? 'bg-blue-600' : 'bg-gray-200'} rounded-full`}>
                    <div className={`absolute top-[2px] left-[2px] bg-white border rounded-full w-4 h-4 transition-all ${isActiveRole ? 'translate-x-full' : ''}`}></div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </fieldset>
        {/* Save Button */}
        <fieldset className="">
          <div className="flex justify-end">
            <button type='submit' className="usbutton bg-blue-500 hover:bg-blue-600 text-white py-2 px-4"><i className="fa-regular fa-floppy-disk me-1"></i>{" "}{isEdit ? 'Update' : 'Save'}</button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
const InputFloating = ({ label, type, name, value, onChange, error }) => (
  <div className="relative mb-4">
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      className={`block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border ${error ? 'border-red-500' : 'border-gray-300'} appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer`}
      placeholder=" " // This is important for floating label behavior
    />
    <label
      className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5`}
    >
      {label}
    </label>
  </div>
);

const SelectWithIcon = ({ label, options = [], name, value, onChange, error }) => {
  return (
    <div className="relative mb-4">
      <MenuOutlined className="absolute left-3 top-0 bottom-0 pointer-events-none" />
      <select
        className={`block w-full py-3.5 pl-10 pr-3 text-gray-900 bg-transparent border ${error ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-blue-500`}
        name={name}
        value={value}  // Bind value to formData
        onChange={onChange} // Directly pass to handleChange
      >
        <option value="">{label}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};



export default AddStaff;
