import React, { useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import MonthFinanceCard from "./MonthFinanceCard";

const FinanceGraph = () => {
  const [selectedRange, setSelectedRange] = useState("thisYear");

  // Example datasets for income, expense, and profit for different time ranges
  const dataSets = {
    thisYear: [
      { month: "Jan", income: 1000, expense: 500, profit: 500 },
      { month: "Feb", income: 1200, expense: 700, profit: 500 },
      { month: "Mar", income: 800, expense: 400, profit: 400 },
      { month: "Apr", income: 1500, expense: 800, profit: 700 },
      { month: "May", income: 1800, expense: 1200, profit: 600 },
      { month: "Jun", income: 1400, expense: 600, profit: 800 },
      { month: "Jul", income: 2000, expense: 1000, profit: 1000 },
      { month: "Aug", income: 2200, expense: 1100, profit: 1100 },
      { month: "Sep", income: 2500, expense: 1300, profit: 1200 },
      { month: "Oct", income: 3000, expense: 1500, profit: 1500 },
      { month: "Nov", income: 2700, expense: 1300, profit: 1400 },
      { month: "Dec", income: 3100, expense: 1600, profit: 1500 },
    ],
    thisMonth: [
      { week: "Week 1", income: 500, expense: 300, profit: 200 },
      { week: "Week 2", income: 700, expense: 400, profit: 300 },
      { week: "Week 3", income: 600, expense: 350, profit: 250 },
      { week: "Week 4", income: 800, expense: 450, profit: 350 },
    ],
    thisWeek: [
      { day: "Mon", income: 100, expense: 50, profit: 50 },
      { day: "Tue", income: 120, expense: 60, profit: 60 },
      { day: "Wed", income: 150, expense: 80, profit: 70 },
      { day: "Thu", income: 130, expense: 70, profit: 60 },
      { day: "Fri", income: 170, expense: 90, profit: 80 },
      { day: "Sat", income: 200, expense: 100, profit: 100 },
      { day: "Sun", income: 180, expense: 90, profit: 90 },
    ],
    lastFiveYears: [
      { year: "2019", income: 12000, expense: 8000, profit: 4000 },
      { year: "2020", income: 14000, expense: 9000, profit: 5000 },
      { year: "2021", income: 16000, expense: 10000, profit: 6000 },
      { year: "2022", income: 18000, expense: 11000, profit: 7000 },
      { year: "2023", income: 20000, expense: 12000, profit: 8000 },
    ],
  };

  // Decide what data to show based on the selected range
  const data = dataSets[selectedRange];

  return (
    <div className="w-full flex flex-col px-4 py-5 bg-white">
      {/* Dropdown for selecting the range */}
      <div className="flex justify-between ">
        <h1 className="font-bold text-lg">Finance Summary</h1>
        <div>
          <select
            value={selectedRange}
            onChange={(e) => setSelectedRange(e.target.value)}
            className="py-2 px-3 border focus:outline-none focus:ring-1 focus:ring-themeColor"
          >
            <option value="thisYear">This Year</option>
            <option value="thisMonth">This Month</option>
            <option value="thisWeek">This Week</option>
            <option value="lastFiveYears">Last 5 Years</option>
          </select>
        </div>
      </div>

      {/* Responsive Area Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorIncome" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#48A7F8" stopOpacity={0.3} />
              <stop offset="95%" stopColor="#48A7F8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorExpense" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FF7E79" stopOpacity={0.3} />
              <stop offset="95%" stopColor="#FF7E79" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorProfit" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3FBD6F" stopOpacity={0.3} />
              <stop offset="95%" stopColor="#3FBD6F" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={
              selectedRange === "lastFiveYears"
                ? "year"
                : selectedRange === "thisWeek"
                ? "day"
                : selectedRange === "thisMonth"
                ? "week"
                : "month"
            }
            tick={{ fontSize: 12, fill: "#666" }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis tick={{ fontSize: 12, fill: "#666" }} axisLine={false} tickLine={false} />
          <Tooltip
            contentStyle={{
              borderRadius: 8,
              boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
            }}
          />
          <Legend verticalAlign="top" height={36} />
          {/* Lines for Income, Expense, and Profit without fill */}
          <Line
            type="monotone"
            dataKey="income"
            stroke="#48A7F8"
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="expense"
            stroke="#FF7E79"
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="profit"
            stroke="#3FBD6F"
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FinanceGraph;
