import React from "react";

const AttendanceCard = () => {
  return (
    <div className="p-2 bg-white">
      <div className="relative overflow-hidden border border-gray-200 max-h-[25rem]">
        {/* Table Wrapper */}
        <div className="overflow-y-auto max-h-[25rem] scrollbar-hidden">
          <table className="min-w-full text-sm text-gray-700">
            {/* Sticky Header */}
            <thead className="bg-gradient-to-r from-blue-500 to-blue-700 text-white sticky top-0 z-10">
              <tr>
                <th className="px-6 py-3 text-left font-semibold uppercase tracking-wider">
                  Class
                </th>
                <th className="px-6 py-3 text-center font-semibold uppercase tracking-wider">
                  Present
                </th>
                <th className="px-6 py-3 text-center font-semibold uppercase tracking-wider">
                  Absent
                </th>
                <th className="px-6 py-3 text-center font-semibold uppercase tracking-wider">
                  Late
                </th>
                <th className="px-6 py-3 text-center font-semibold uppercase tracking-wider">
                  Leave
                </th>
              </tr>
            </thead>
            {/* Scrollable Body */}
            <tbody className="bg-white divide-y divide-gray-200">
              {[
                ["PG", 100, 20, 5, 15],
                ["Nursery", 120, 15, 10, 10],
                ["Prep", 90, 30, 5, 5],
                ["One", 100, 20, 5, 15],
                ["Two", 120, 15, 10, 10],
                ["Three", 90, 30, 5, 5],
                ["Four", 110, 10, 15, 15],
                ["Five", 100, 20, 5, 15],
                ["Six", 120, 15, 10, 10],
                ["Seven", 90, 30, 5, 5],
                ["Eight(Boys)", 100, 20, 5, 15],
                ["Eight(Girls)", 120, 15, 10, 10],
                ["Nine(Boys)", 100, 20, 5, 15],
                ["Nine(Girls)", 120, 15, 10, 10],
                ["Ten(Boys)", 100, 20, 5, 15],
                ["Ten(Girls)", 120, 15, 10, 10],
              ].map(([className, present, absent, late, leave], index) => (
                <tr
                  key={className}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-gray-100"
                  } hover:bg-gray-200`}
                >
                  <td className="px-6 py-3 text-left font-medium text-gray-800">
                    {className}
                  </td>
                  <td className="px-6 py-3 text-center">{present}</td>
                  <td className="px-6 py-3 text-center">{absent}</td>
                  <td className="px-6 py-3 text-center">{late}</td>
                  <td className="px-6 py-3 text-center">{leave}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AttendanceCard;
