import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell } from "recharts";

const TodayFinanceGraph = () => {
  const data = [
    { name: "Outgoing", value: 38899, color: "#FF7E79" },
    { name: "Incoming", value: 58899, color: "#48A7F8" },
    { name: "Balance", value: 20000, color: "#3FBD6F" },
  ];

  const [time, setTime] = useState("");

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      let hours = now.getHours();
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      setTime(`${hours}:${minutes}:${seconds} ${ampm}`);
    };

    updateClock();
    const interval = setInterval(updateClock, 1000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div className="w-full flex flex-col px-6 py-5 bg-white">
      <h1 className="font-semibold text-lg mb-4 text-start">Today's Finance Overview</h1>
      <div className="flex flex-col items-center">
        {/* Pie Chart */}
        <PieChart width={250} height={250}>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={70} // Adjust this for the ring thickness
            outerRadius={120} // Outer edge of the ring
            fill="#8884d8"
            labelLine={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {/* Center Clock */}
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fontFamily: "'Poppins', sans-serif", // Modern font
              fontSize: "20px", // Larger size
              fill: "url(#gradient)", // Gradient fill
              fontWeight: "bold",
              textShadow: "0 1px 3px rgba(0, 0, 0, 0.3)", // Subtle shadow
            }}
          >
            {time}
          </text>
          {/* Gradient for Clock Text */}
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#48A7F8" />
              <stop offset="100%" stopColor="#3FBD6F" />
            </linearGradient>
          </defs>
        </PieChart>

        {/* Legend */}
        <div className="mt-6 w-full">
          {data.map((item, index) => (
            <div key={index} className="flex items-center">
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: item.color }}
              ></div>
              <span className="ml-2 text-sm">{item.name}</span>
              <span className="ml-auto text-sm font-semibold">
                Rs.{item.value.toLocaleString()}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TodayFinanceGraph;
