import React from "react";

const FeeReceivingHistoryModal = ({ handleClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div
        className="bg-white rounded-lg w-3/4 transform transition-all duration-300 ease-in-out"
        style={{ maxWidth: "800px" }} // Optional inline styling for maximum width
      >
        <h4 className="text-lg font-bold p-2 border-b-2 border-themeColor text-left text-themeColor">
          Receiving History
        </h4>
        <table className="w-full text-center bg-white overflow-hidden">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2">Month</th>
              <th className="p-2">Total Bill</th>
              <th className="p-2">Total Receive</th>
              <th className="p-2">Balance</th>
              <th className="p-2">Date</th>
            </tr>
          </thead>
          <tbody>
            {/* Sample history rows */}
            <tr className="border-b hover:bg-gray-50">
              <td className="text-gray-600 p-2">June</td>
              <td className="text-gray-600 p-2">3450</td>
              <td className="text-gray-600 p-2">3000</td>
              <td className="text-gray-600 p-2">450</td>
              <td className="text-gray-600 p-2">6/14/2024</td>
            </tr>
            <tr className="border-b hover:bg-gray-50">
              <td className="text-gray-600 p-2">May</td>
              <td className="text-gray-600 p-2">3450</td>
              <td className="text-gray-600 p-2">3000</td>
              <td className="text-gray-600 p-2">450</td>
              <td className="text-gray-600 p-2">6/14/2024</td>
            </tr>
          </tbody>
        </table>

        {/* Modal Footer */}
        <div
          className="flex justify-end p-4 space-x-2 bg-gray-100 rounded-b-lg"
        >
          <button
            onClick={handleClose}
            className="bg-blue-600 text-white py-2 px-4 hover:bg-blue-700 transition-all duration-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeeReceivingHistoryModal;
