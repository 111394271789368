// axiosInstance.js
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://dotnet.rapidmis.com', // Set your base URL here
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => response, // Simply return the response if it's successful
  (error) => {
    // If the error response status is 401, navigate to login
    if (error.response && error.response.status === 401) {
      // Clear any saved tokens or sensitive data
      localStorage.removeItem('authToken');
      localStorage.removeItem('instanceTypeId');
      localStorage.removeItem('instanceId');
      localStorage.removeItem('employeeRoleData');

      // Redirect to the login page
      window.location.href = '/'; // Redirect to login page
    }

    return Promise.reject(error); // Pass the error further to handle it where necessary
  }
);

export default axiosInstance;
