import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Empty } from "antd";

function AddDepartment() {
  const [departmentData, setDepartmentData] = useState([]);
  const [newDepartment, setNewDepartment] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const userRoleData = JSON.parse(localStorage.getItem("employeeRoleData"));

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = () => {
    axios
      .get("https://dotnet.rapidmis.com/departmentgetall", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleAddNewRow = () => {
    const newRow = { departmentId: null, departmentName: "", isActive: true };
    setNewDepartment(newRow);
  };

  const handleSaveNewDepartment = () => {
    if (!newDepartment || !newDepartment.departmentName?.trim()) {
      alert("Please enter Department Name");
      return;
    }

    axios
      .post("https://dotnet.rapidmis.com/departmentcreate", newDepartment, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setNewDepartment(null);
        toast.success("Department added successfully!");
        fetchDepartments();
      })
      .catch((error) => console.error("Error adding new department:", error));
  };

  const handleNewDepartmentInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewDepartment({
      ...newDepartment,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleInputChange = (e, id) => {
    const { name, value, type, checked } = e.target;
    setUpdatedData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  const handleEdit = (departmentItem) => {
    setEditingId(departmentItem.departmentId);
    setUpdatedData({
      [departmentItem.departmentId]: {
        departmentName: departmentItem.departmentName,
        isActive: departmentItem.isActive,
      },
    });
  };

  const handleSave = (id) => {
    const updatedDepartment = updatedData[id];
    axios
      .put(
        `https://dotnet.rapidmis.com/departmentupdate/${id}`,
        {
          departmentId: id,
          departmentName: updatedDepartment.departmentName || "",
          isActive: updatedDepartment.isActive,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        setEditingId(null);
        toast.success("Department updated successfully!");
        fetchDepartments();
      })
      .catch((error) => console.error("Error updating data:", error));
  };

  const handleCancel = () => {
    setEditingId(null);
    setUpdatedData({});
  };

  const handleCancelNewDepartment = () => {
    setNewDepartment(null);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://dotnet.rapidmis.com/departmentdelete/${id}`,
        {
          departmentId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setDepartmentData((prevData) =>
        prevData.filter((item) => item.departmentId !== id)
      );
      toast.success("Department deleted successfully!");
    } catch (error) {
      console.error(
        "Error deleting department:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const isActionAvailable = (moduleId, featureId, actionId) => {
    if (
      !userRoleData ||
      !userRoleData.rolePermission ||
      !userRoleData.rolePermission.moduleList
    )
      return false;

    return userRoleData.rolePermission.moduleList.some(
      (module) =>
        module.moduleId === moduleId &&
        module.featureList.some(
          (feature) =>
            feature.featureId === featureId &&
            feature.actionList.some((action) => action.actionId === actionId)
        )
    );
  };

  return (
    <div
      className="bg-white p-2 space-y-2"
      style={{ fontFamily: "Roboto, sans-serif" }}
    >
      <h4 className="text-lg font-bold text-themeColor1 text-left border-b-2 border-themeColor1">
        DEPARTMENT LIST
      </h4>
      {isActionAvailable(2, 201, 20102) && (
        <div className="flex justify-end">
          <button
            className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600"
            onClick={handleAddNewRow}
          >
            <i className="fa-solid fa-plus"></i> Add New
          </button>
        </div>
      )}
      {isActionAvailable(2, 201, 20101) && (
        <div className="overflow-y-auto shadow-md">
          <table className="min-w-full bg-white">
            <thead className="bg-themeColor1 sticky top-0 z-10">
              <tr>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  <span>Sr</span>
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  <span>Department Name</span>
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-white">
                  <span>Status</span>
                </th>
                {isActionAvailable(2, 201, 20103) && (
                  <th className="py-2 px-4 border-b border-gray-300 text-white">
                    <span>Action</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {newDepartment && (
                <tr>
                  <td className="py-2 px-4 border-b border-gray-300"></td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <input
                      type="text"
                      className=" border-b border-gray-500 w-full px-2 py-1"
                      name="departmentName"
                      value={newDepartment.departmentName}
                      onChange={handleNewDepartmentInputChange}
                    />
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="isActive"
                        checked={newDepartment.isActive}
                        onChange={handleNewDepartmentInputChange}
                        className="sr-only peer"
                      />
                      <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <i
                      className="fa-regular fa-floppy-disk cursor-pointer mr-2"
                      onClick={handleSaveNewDepartment}
                    ></i>
                    <i
                      className="fa-regular fa-circle-xmark cursor-pointer"
                      onClick={handleCancelNewDepartment}
                    ></i>
                  </td>
                </tr>
              )}
              {departmentData.length === 0 ? (
                <tr>
                  <td colSpan="4">
                    <div className="flex flex-col items-center justify-center my-10">
                      <Empty />
                    </div>
                  </td>
                </tr>
              ) : (
                departmentData.map((departmentItem, index) => (
                  <tr
                    key={departmentItem.departmentId}
                    className="hover:bg-gray-50"
                  >
                    <td className="py-2 px-4 border-b border-gray-300">
                      {index + 1}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {editingId === departmentItem.departmentId ? (
                        <input
                          type="text"
                          className="py-2 px-4 border-b border-gray-300"
                          name="departmentName"
                          value={
                            updatedData[departmentItem.departmentId]
                              ?.departmentName || ""
                          }
                          onChange={(e) =>
                            handleInputChange(e, departmentItem.departmentId)
                          }
                        />
                      ) : (
                        departmentItem.departmentName
                      )}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          name="isActive"
                          checked={
                            editingId === departmentItem.departmentId
                              ? updatedData[departmentItem.departmentId]
                                  ?.isActive
                              : departmentItem.isActive
                          }
                          onChange={(e) =>
                            handleInputChange(e, departmentItem.departmentId)
                          }
                          className={`sr-only peer ${
                            editingId === departmentItem.departmentId
                              ? "cursor-pointer"
                              : "cursor-default"
                          }`}
                          disabled={editingId !== departmentItem.departmentId}
                        />
                        <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </td>
                    {isActionAvailable(2, 201, 20103) && (
                      <td className="py-2 px-4 border-b border-gray-300">
                        {editingId === departmentItem.departmentId ? (
                          <>
                            <i
                              className="fa-regular fa-floppy-disk cursor-pointer mr-2"
                              onClick={() =>
                                handleSave(departmentItem.departmentId)
                              }
                            ></i>
                            <i
                              className="fa-regular fa-circle-xmark cursor-pointer"
                              onClick={handleCancel}
                            ></i>
                          </>
                        ) : (
                          <>
                            <i
                              className="fa-regular fa-pen-to-square cursor-pointer mr-2"
                              onClick={() => handleEdit(departmentItem)}
                            ></i>
                            {isActionAvailable(2, 201, 20104) && (
                              <i
                                className="fa-regular fa-trash-can cursor-pointer"
                                onClick={() =>
                                  handleDelete(departmentItem.departmentId)
                                }
                              ></i>
                            )}
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default AddDepartment;
