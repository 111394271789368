import React from "react";
import TodayFinanceGraph from "../components/TodayFinanceGraph";
import AttendanceCard from "../components/AttendanceCard";
import ActiveStudents from "../components/ActiveStudents";
import ActiveStaff from "../components/ActiveStaff";
import StudentStrengthChart from "../components/StudentStrengthChart";
import BirthdayCard from "../components/BirthdayCard";
import MonthFinanceCard from "../components/MonthFinanceCard";
import EventsCard from "../components/EventsCard";
import FinanceGraph from "../components/FinanceGraph";
import AttendanceGraph from "../components/AttendanceGraph";

function Dashboard() {
  return (
    <div className="flex flex-col space-y-3 w-full">
      <MonthFinanceCard />
      <div className="flex gap-3">
        <div className="w-8/12 flex flex-col space-y-3">
          <FinanceGraph />
          <StudentStrengthChart />
        </div>
        <div className="w-4/12 flex flex-col space-y-3">
          <TodayFinanceGraph />
          <ActiveStudents />
          <ActiveStaff />
          <BirthdayCard />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="w-8/12 flex flex-col space-y-3">
        <AttendanceCard/>
        </div>
        <div className="w-4/12 flex flex-col space-y-3">
        <AttendanceGraph/>
        </div>
      </div>
      <EventsCard />
      {/* Left Column */}
      {/* <div className="col-span-12 md:col-span-3">
          <div className="flex flex-col space-y-2">
            <div className="">
              <Clock />
            </div>
            <div className="">
              <ActiveStudents />
            </div>
            <div className="">
              <ActiveStaff />
            </div>
            <div className="">
              <BirthdayCard />
            </div>
          </div>
        </div> */}

      {/* Middle Column */}
      {/* <div className="col-span-12 md:col-span-5">
          <div className="flex flex-col space-y-2">
            <div className="bg-white">
              <StudentStrengthChart />
            </div>
            <div className="bg-white">
              <YearFinanceChart />
            </div>
          </div>
        </div> */}

      {/* Right Column */}
      {/* <div className="col-span-12 md:col-span-4">
          <div className="flex flex-col space-y-2">
            <div className="">
              <FinanceCard />
            </div>
            <div className="">
              <MonthFinanceCard />
            </div>
            <div className="">
              <EventsCard />
            </div>
            <div className="">
              <AttendanceCard />
            </div>
          </div>
        </div> */}
    </div>
  );
}

export default Dashboard;
