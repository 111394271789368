import React from 'react';

const MonthFinanceCard = () => {
  return (
    <div className="flex justify-between gap-3">
      {/* Total */}
      <div className="flex flex-col items-center bg-gradient-to-r from-blue-500 to-indigo-500 text-white p-4 w-1/4">
        <div className="text-xl font-semibold">Total</div>
        <div className="flex items-center justify-center mt-2 text-3xl font-bold">
          <i className="fas fa-dollar-sign mr-2"></i>
          <span>38,899</span>
        </div>
      </div>

      {/* Received */}
      <div className="flex flex-col items-center bg-gradient-to-r from-green-400 to-green-600 text-white p-4 w-1/4">
        <div className="text-xl font-semibold">Received</div>
        <div className="flex items-center justify-center mt-2 text-3xl font-bold">
          <i className="fas fa-credit-card mr-2"></i>
          <span>25,000</span>
        </div>
      </div>

      {/* Adjustment */}
      <div className="flex flex-col items-center bg-gradient-to-r from-yellow-400 to-yellow-600 text-white p-4 w-1/4">
        <div className="text-xl font-semibold">Adjustment</div>
        <div className="flex items-center justify-center mt-2 text-3xl font-bold">
          <i className="fas fa-balance-scale mr-2"></i>
          <span>3,500</span>
        </div>
      </div>

      {/* Remaining */}
      <div className="flex flex-col items-center bg-gradient-to-r from-purple-400 to-purple-600 text-white p-4 w-1/4">
        <div className="text-xl font-semibold">Remaining</div>
        <div className="flex items-center justify-center mt-2 text-3xl font-bold">
          <i className="fas fa-dollar-sign mr-2"></i>
          <span>10,399</span>
        </div>
      </div>
    </div>
  );
};

export default MonthFinanceCard;
