import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../utils/axiosInstance";

const LoginComponent = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axiosInstance.post("/authenticationLogin", {
        userName,
        password,
      });

      const data = response.data;

      if (response.status === 200) {
        const { accessToken, instanceTypeId, instanceId } = data;

        localStorage.setItem("authToken", accessToken);
        localStorage.setItem("instanceTypeId", instanceTypeId);
        localStorage.setItem("instanceId", instanceId);

        toast.success("Login successful!");

        await fetchEmployeeRole(instanceId, accessToken);

        if (instanceTypeId === 1) {
          setTimeout(() => {
            navigate("/parentPanel");
          }, 1000);
        } else {
          setTimeout(() => {
            navigate("/panel");
          }, 1000);
        }
      } else {
        toast.error(data.message || "Login failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error logging in:", error);
      toast.error("An error occurred during login.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEmployeeRole = async (instanceId, authToken) => {
    try {
      const response = await axiosInstance.get(
        `/employeewithpermissionbyid/${instanceId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data) {
        localStorage.setItem("employeeRoleData", JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  return (
    <div className="h-full flex items-center justify-center">
      <div className="max-w-md w-full bg-white py-6 px-8 rounded-xl border-4 border-gray-200">
        <h2 className="text-2xl font-bold text-gray-800 mb-2 ">
          Login to Your Account
        </h2>
        <p className="text-gray-500 mb-6">
          Enter your credentials to access the system.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="relative mb-4">
            <input
              type="text"
              id="floatingInput"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floatingInput"
              className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor"
            >
              Login Id
            </label>
          </div>

          <div className="relative mb-4">
            <input
              type={showPassword ? "text" : "password"}
              id="floatingPassword"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="block w-full px-2.5 pb-2.5 pt-4 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floatingPassword"
              className="absolute text-sm text-themeColor duration-300 transform -translate-y-4 scale-75 top-2 left-2 z-10 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-themeColor"
            >
              Password
            </label>
            <i
              className={`absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer ${
                showPassword ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"
              }`}
              onClick={togglePasswordVisibility}
            ></i>
          </div>

          <button
            className="bg-themeColor text-white w-full py-2 mt-2 flex justify-center items-center"
            type="submit"
            disabled={isLoading} // Disable button when loading
          >
            {isLoading ? (
              <div className="flex items-center space-x-2">
                <div className="loader w-5 h-5 border-2 border-white rounded-full border-t-transparent animate-spin"></div>
                <span>Login</span>
              </div>
            ) : (
              "Login"
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default LoginComponent;
