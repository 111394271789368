import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts';

const StudentStrengthChart = () => {
  const [selectedRange, setSelectedRange] = useState("thisYear");

  // Example datasets for student strength in different time ranges
  const dataSets = {
    thisYear: [
      { month: "Jan", strength: 200 },
      { month: "Feb", strength: 220 },
      { month: "Mar", strength: 210 },
      { month: "Apr", strength: 240 },
      { month: "May", strength: 230 },
      { month: "Jun", strength: 250 },
      { month: "Jul", strength: 260 },
      { month: "Aug", strength: 270 },
      { month: "Sep", strength: 280 },
      { month: "Oct", strength: 290 },
      { month: "Nov", strength: 300 },
      { month: "Dec", strength: 310 },
    ],
    lastYear: [
      { month: "Jan", strength: 180 },
      { month: "Feb", strength: 200 },
      { month: "Mar", strength: 190 },
      { month: "Apr", strength: 220 },
      { month: "May", strength: 210 },
      { month: "Jun", strength: 230 },
      { month: "Jul", strength: 240 },
      { month: "Aug", strength: 250 },
      { month: "Sep", strength: 260 },
      { month: "Oct", strength: 270 },
      { month: "Nov", strength: 280 },
      { month: "Dec", strength: 290 },
    ],
    thisMonth: [
      { week: "Week 1", strength: 50 },
      { week: "Week 2", strength: 60 },
      { week: "Week 3", strength: 70 },
      { week: "Week 4", strength: 80 },
    ]
  };

  // Decide what data to show based on the selected range
  const data = dataSets[selectedRange];

  return (
    <div className="w-full flex flex-col px-4 py-5 bg-white">
      {/* Dropdown for selecting the range */}
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Student Strength</h1>
        <div>
          <select
            value={selectedRange}
            onChange={(e) => setSelectedRange(e.target.value)}
            className="py-2 px-3 border focus:outline-none focus:ring-1 focus:ring-themeColor"
          >
            <option value="thisYear">This Year</option>
            <option value="lastYear">Last Year</option>
            <option value="thisMonth">This Month</option>
          </select>
        </div>
      </div>

      {/* Responsive BarChart */}
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={
              selectedRange === "currentStrength"
                ? "day"
                : selectedRange === "thisMonth"
                ? "week"
                : "month"
            }
            tick={{ fontSize: 12, fill: "#666" }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis tick={{ fontSize: 12, fill: "#666" }} axisLine={false} tickLine={false} />
          <Tooltip contentStyle={{ borderRadius: 8, boxShadow: "0px 4px 8px rgba(0,0,0,0.2)" }} />
          <Legend verticalAlign="top" height={36} />
          <Bar
            dataKey="strength"
            fill="#48A7F8"
            barSize={30}
          >
            <LabelList dataKey="strength" position="top" fontSize={12} fill="#333" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StudentStrengthChart;
